import React from "react";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Services = () => {
  return (
    <>
      <a
        class="header-whatsapp"
        href="https://wa.me/2348113201322"
        aria-label="Chat with us on WhatsApp"
      >
        <svg
          class="whatsapp-icon svg-primary"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <path d="M16 0c-8.837 0-16 7.163-16 16 0 2.825 0.737 5.607 2.137 8.048l-2.137 7.952 7.933-2.127c2.42 1.37 5.173 2.127 8.067 2.127 8.837 0 16-7.163 16-16s-7.163-16-16-16zM16 29.467c-2.482 0-4.908-0.646-7.07-1.87l-0.507-0.292-4.713 1.262 1.262-4.669-0.292-0.508c-1.207-2.100-1.847-4.507-1.847-6.978 0-7.51 6.11-13.62 13.62-13.62 7.51 0 13.62 6.11 13.62 13.62s-6.11 13.62-13.62 13.62zM21.305 19.26c-0.346-0.174-2.049-1.007-2.366-1.123-0.316-0.117-0.547-0.174-0.776 0.174s-0.892 1.123-1.094 1.347c-0.201 0.224-0.402 0.251-0.748 0.076-0.346-0.174-1.461-0.539-2.785-1.722-1.031-0.922-1.727-2.061-1.929-2.407-0.201-0.346-0.022-0.533 0.152-0.707 0.156-0.155 0.346-0.402 0.518-0.603 0.174-0.201 0.231-0.346 0.346-0.571 0.117-0.224 0.058-0.427-0.028-0.603s-0.776-1.87-1.063-2.565c-0.28-0.672-0.56-0.58-0.776-0.591-0.2-0.008-0.428-0.010-0.656-0.010s-0.603 0.085-0.92 0.427c-0.316 0.346-1.206 1.179-1.206 2.873s1.235 3.333 1.406 3.561c0.174 0.224 2.425 3.732 5.872 5.234 0.821 0.354 1.462 0.566 1.962 0.724 0.825 0.262 1.577 0.225 2.168 0.137 0.662-0.099 2.049-0.835 2.335-1.642 0.288-0.807 0.288-1.501 0.201-1.642-0.086-0.14-0.316-0.224-0.662-0.398z"></path>
        </svg>
      </a>
      <body class="page-template page-template-elementor_header_footer page page-id-623 ehf-template-hello-elementor ehf-stylesheet-hello-elementor jkit-color-scheme elementor-default elementor-template-full-width elementor-kit-3 elementor-page elementor-page-623">
        <div id="page" class="jkit-template  site">
          <Navbar />
          <div
            data-elementor-type="wp-page"
            data-elementor-id="837"
            class="elementor elementor-837"
          >
            <section
              class="elementor-section elementor-top-section elementor-element elementor-element-8c5baab elementor-section-content-middle elementor-reverse-tablet elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="8c5baab"
              data-element_type="section"
              data-settings='{"background_background":"classic"}'
            >
              <div class="elementor-background-overlay"></div>
              <div class="elementor-container elementor-column-gap-default">
                <div
                  class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8719c1b"
                  data-id="8719c1b"
                  data-element_type="column"
                >
                  <div class="elementor-widget-wrap elementor-element-populated">
                    <div
                      class="elementor-element elementor-element-e44243d elementor-widget elementor-widget-heading"
                      data-id="e44243d"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div class="elementor-widget-container">
                        <h2 class="elementor-heading-title elementor-size-default">
                          Services
                        </h2>{" "}
                      </div>
                    </div>
                    <div
                      class="elementor-element elementor-element-1bb4d6f elementor-icon-list--layout-inline elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                      data-id="1bb4d6f"
                      data-element_type="widget"
                      data-widget_type="icon-list.default"
                    >
                      <div class="elementor-widget-container">
                        <ul class="elementor-icon-list-items elementor-inline-items">
                          <li class="elementor-icon-list-item elementor-inline-item">
                            <span class="elementor-icon-list-text">Home</span>
                          </li>
                          <li class="elementor-icon-list-item elementor-inline-item">
                            <span class="elementor-icon-list-icon">
                              <i
                                aria-hidden="true"
                                class="fas fa-chevron-right"
                              ></i>{" "}
                            </span>
                            <span class="elementor-icon-list-text">
                              Services
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              class="elementor-section elementor-top-section elementor-element elementor-element-41547ec elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="41547ec"
              data-element_type="section"
            >
              <div class="elementor-container elementor-column-gap-default">
                <div
                  class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-54cdaac"
                  data-id="54cdaac"
                  data-element_type="column"
                >
                  <div class="elementor-widget-wrap elementor-element-populated">
                    <div
                      class="elementor-element elementor-element-3e29c65 elementor-align-center elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                      data-id="3e29c65"
                      data-element_type="widget"
                      data-widget_type="icon-list.default"
                    >
                      <div class="elementor-widget-container">
                        <ul class="elementor-icon-list-items">
                          <li class="elementor-icon-list-item">
                            <span class="elementor-icon-list-icon">
                              <i
                                aria-hidden="true"
                                class="far fa-dot-circle"
                              ></i>{" "}
                            </span>
                            <span class="elementor-icon-list-text">
                              Our Services
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      class="elementor-element elementor-element-62b1ea1 elementor-widget elementor-widget-heading"
                      data-id="62b1ea1"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div class="elementor-widget-container">
                        <h2 class="elementor-heading-title elementor-size-default">
                          We Prioritize Customer Satisfaction
                        </h2>{" "}
                      </div>
                    </div>
                    <div
                      class="elementor-element elementor-element-201a90f elementor-widget elementor-widget-text-editor"
                      data-id="201a90f"
                      data-element_type="widget"
                      data-widget_type="text-editor.default"
                    >
                      <div class="elementor-widget-container">
                        <p>
                          Our services are designed to be user-friendly,
                          reliable, and tailored to meet your needs. Whether you
                          are accessing cash, sending money, paying bills etc
                        </p>{" "}
                      </div>
                    </div>
                    <section
                      class="elementor-section elementor-inner-section elementor-element elementor-element-9901c5b elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                      data-id="9901c5b"
                      data-element_type="section"
                    >
                      <div class="elementor-container elementor-column-gap-no">
                        <div
                          class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-d6aec4d"
                          data-id="d6aec4d"
                          data-element_type="column"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div class="elementor-widget-wrap elementor-element-populated">
                            <div
                              class="elementor-element elementor-element-9b474fa elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon"
                              data-id="9b474fa"
                              data-element_type="widget"
                              data-widget_type="icon.default"
                            >
                              <div class="elementor-widget-container">
                                <div class="elementor-icon-wrapper">
                                  <div class="elementor-icon elementor-animation-float">
                                    <i
                                      aria-hidden="true"
                                      class="jki jki-padlock-1-light"
                                    ></i>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="elementor-element elementor-element-fe37496 jkit-equal-height-disable elementor-widget elementor-widget-jkit_icon_box"
                              data-id="fe37496"
                              data-element_type="widget"
                              data-widget_type="jkit_icon_box.default"
                            >
                              <div class="elementor-widget-container">
                                <div class="jeg-elementor-kit jkit-icon-box icon-position- elementor-animation- jeg_module_837_1_66a66a16c3c4c">
                                  <div class="jkit-icon-box-wrapper hover-from-left">
                                    <div class="icon-box icon-box-body">
                                      <h3 class="title">
                                        {" "}
                                        POS (Get cash, send cash etc)
                                      </h3>
                                      <p class="icon-box-description">
                                        Access cash quickly and conveniently,
                                        whenever and wherever you need it,
                                        Safely and securely send money to family
                                        and friends.
                                      </p>
                                    </div>
                                  </div>
                                </div>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-18c082f"
                          data-id="18c082f"
                          data-element_type="column"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div class="elementor-widget-wrap elementor-element-populated">
                            <div
                              class="elementor-element elementor-element-a70a428 elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon"
                              data-id="a70a428"
                              data-element_type="widget"
                              data-widget_type="icon.default"
                            >
                              <div class="elementor-widget-container">
                                <div class="elementor-icon-wrapper">
                                  <div class="elementor-icon elementor-animation-float">
                                    <i
                                      aria-hidden="true"
                                      class="jki jki-pie-chart1-light"
                                    ></i>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="elementor-element elementor-element-a72cf63 jkit-equal-height-disable elementor-widget elementor-widget-jkit_icon_box"
                              data-id="a72cf63"
                              data-element_type="widget"
                              data-widget_type="jkit_icon_box.default"
                            >
                              <div class="elementor-widget-container">
                                <div class="jeg-elementor-kit jkit-icon-box icon-position- elementor-animation- jeg_module_837_2_66a66a16c5bf5">
                                  <div class="jkit-icon-box-wrapper hover-from-left">
                                    <div class="icon-box icon-box-body">
                                      <h3 class="title">Utility Bills</h3>
                                      <p class="icon-box-description">
                                        Pay your utility bills, school fees, and
                                        other expenses with ease.
                                      </p>
                                    </div>
                                  </div>
                                </div>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-c02a9b8 elementor-hidden-tablet"
                          data-id="c02a9b8"
                          data-element_type="column"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div class="elementor-widget-wrap elementor-element-populated">
                            <div
                              class="elementor-element elementor-element-c6e88b4 elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon"
                              data-id="c6e88b4"
                              data-element_type="widget"
                              data-widget_type="icon.default"
                            >
                              <div class="elementor-widget-container">
                                <div class="elementor-icon-wrapper">
                                  <div class="elementor-icon elementor-animation-float">
                                    <i
                                      aria-hidden="true"
                                      class="jki jki-thumbs-up-line"
                                    ></i>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="elementor-element elementor-element-314e5c6 jkit-equal-height-disable elementor-widget elementor-widget-jkit_icon_box"
                              data-id="314e5c6"
                              data-element_type="widget"
                              data-widget_type="jkit_icon_box.default"
                            >
                              <div class="elementor-widget-container">
                                <div class="jeg-elementor-kit jkit-icon-box icon-position- elementor-animation- jeg_module_837_3_66a66a16c9622">
                                  <div class="jkit-icon-box-wrapper hover-from-left">
                                    <div class="icon-box icon-box-body">
                                      <h3 class="title">Airtime and Data</h3>
                                      <p class="icon-box-description">
                                        Purchase airtime and data bundles
                                        instantly for all major networks.
                                      </p>
                                    </div>
                                  </div>
                                </div>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      class="elementor-section elementor-inner-section elementor-element elementor-element-66f770a elementor-section-content-middle elementor-hidden-desktop elementor-hidden-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                      data-id="66f770a"
                      data-element_type="section"
                    >
                      <div class="elementor-container elementor-column-gap-no">
                        <div
                          class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-9f00342"
                          data-id="9f00342"
                          data-element_type="column"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div class="elementor-widget-wrap elementor-element-populated">
                            <div
                              class="elementor-element elementor-element-ec0c1f0 elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon"
                              data-id="ec0c1f0"
                              data-element_type="widget"
                              data-widget_type="icon.default"
                            >
                              <div class="elementor-widget-container">
                                <div class="elementor-icon-wrapper">
                                  <div class="elementor-icon elementor-animation-float">
                                    <i
                                      aria-hidden="true"
                                      class="jki jki-thumbs-up-line"
                                    ></i>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="elementor-element elementor-element-daea775 jkit-equal-height-disable elementor-widget elementor-widget-jkit_icon_box"
                              data-id="daea775"
                              data-element_type="widget"
                              data-widget_type="jkit_icon_box.default"
                            >
                              <div class="elementor-widget-container">
                                <div class="jeg-elementor-kit jkit-icon-box icon-position- elementor-animation- jeg_module_837_4_66a66a16cc1f8">
                                  <div class="jkit-icon-box-wrapper hover-from-left">
                                    <div class="icon-box icon-box-body">
                                      <h3 class="title">Ajo(Thrift)</h3>
                                      <p class="icon-box-description">
                                        Join our thrift program to save money,
                                        manage your finances better, and achieve
                                        financial freedom.
                                      </p>
                                    </div>
                                  </div>
                                </div>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-56daa70"
                          data-id="56daa70"
                          data-element_type="column"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div class="elementor-widget-wrap elementor-element-populated">
                            <div
                              class="elementor-element elementor-element-27927a6 elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon"
                              data-id="27927a6"
                              data-element_type="widget"
                              data-widget_type="icon.default"
                            >
                              <div class="elementor-widget-container">
                                <div class="elementor-icon-wrapper">
                                  <div class="elementor-icon elementor-animation-float">
                                    <i
                                      aria-hidden="true"
                                      class="jki jki-wallet1-light"
                                    ></i>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="elementor-element elementor-element-546b6e4 jkit-equal-height-disable elementor-widget elementor-widget-jkit_icon_box"
                              data-id="546b6e4"
                              data-element_type="widget"
                              data-widget_type="jkit_icon_box.default"
                            >
                              <div class="elementor-widget-container">
                                <div class="jeg-elementor-kit jkit-icon-box icon-position- elementor-animation- jeg_module_837_5_66a66a16ce15f">
                                  <div class="jkit-icon-box-wrapper hover-from-left">
                                    <div class="icon-box icon-box-body">
                                      <h3 class="title">Ajo(Thrift)</h3>
                                      <p class="icon-box-description">
                                        Join our thrift program to save money,
                                        manage your finances better, and achieve
                                        financial freedom.
                                      </p>
                                    </div>
                                  </div>
                                </div>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      class="elementor-section elementor-inner-section elementor-element elementor-element-6e0d013 elementor-section-content-bottom elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                      data-id="6e0d013"
                      data-element_type="section"
                    >
                      <div class="elementor-container elementor-column-gap-no">
                        <div
                          class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-627105e elementor-hidden-tablet"
                          data-id="627105e"
                          data-element_type="column"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div class="elementor-widget-wrap elementor-element-populated">
                            <div
                              class="elementor-element elementor-element-b2e9685 elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon"
                              data-id="b2e9685"
                              data-element_type="widget"
                              data-widget_type="icon.default"
                            >
                              <div class="elementor-widget-container">
                                <div class="elementor-icon-wrapper">
                                  <div class="elementor-icon elementor-animation-float">
                                    <i
                                      aria-hidden="true"
                                      class="jki jki-wallet1-light"
                                    ></i>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="elementor-element elementor-element-671bfd6 jkit-equal-height-disable elementor-widget elementor-widget-jkit_icon_box"
                              data-id="671bfd6"
                              data-element_type="widget"
                              data-widget_type="jkit_icon_box.default"
                            >
                              <div class="elementor-widget-container">
                                <div class="jeg-elementor-kit jkit-icon-box icon-position- elementor-animation- jeg_module_837_6_66a66a16d0ba9">
                                  <div class="jkit-icon-box-wrapper hover-from-left">
                                    <div class="icon-box icon-box-body">
                                      <h3 class="title">Ajo(Thrift)</h3>
                                      <p class="icon-box-description">
                                        Join our thrift program to save money,
                                        manage your finances better, and achieve
                                        financial freedom.
                                      </p>
                                    </div>
                                  </div>
                                </div>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-2e1feca"
                          data-id="2e1feca"
                          data-element_type="column"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div class="elementor-widget-wrap elementor-element-populated">
                            <div
                              class="elementor-element elementor-element-ccbf9ca elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon"
                              data-id="ccbf9ca"
                              data-element_type="widget"
                              data-widget_type="icon.default"
                            >
                              <div class="elementor-widget-container">
                                <div class="elementor-icon-wrapper">
                                  <div class="elementor-icon elementor-animation-float">
                                    <i
                                      aria-hidden="true"
                                      class="jki jki-support-light"
                                    ></i>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="elementor-element elementor-element-edce3a1 jkit-equal-height-disable elementor-widget elementor-widget-jkit_icon_box"
                              data-id="edce3a1"
                              data-element_type="widget"
                              data-widget_type="jkit_icon_box.default"
                            >
                              <div class="elementor-widget-container">
                                <div class="jeg-elementor-kit jkit-icon-box icon-position- elementor-animation- jeg_module_837_7_66a66a16d2f93">
                                  <div class="jkit-icon-box-wrapper hover-from-left">
                                    <div class="icon-box icon-box-body">
                                      <h3 class="title">
                                        Waste Bill and Electricity bill
                                      </h3>
                                      <p class="icon-box-description">
                                        Pay your waste bill bills, electricity
                                        bill, cable tv(dstc, gotv etc) with
                                        OGAPOS
                                      </p>
                                    </div>
                                  </div>
                                </div>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-eccdef9"
                          data-id="eccdef9"
                          data-element_type="column"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div class="elementor-widget-wrap elementor-element-populated">
                            <div
                              class="elementor-element elementor-element-e2c905c elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon"
                              data-id="e2c905c"
                              data-element_type="widget"
                              data-widget_type="icon.default"
                            >
                              <div class="elementor-widget-container">
                                <div class="elementor-icon-wrapper">
                                  <div class="elementor-icon elementor-animation-float">
                                    <i
                                      aria-hidden="true"
                                      class="jki jki-shopping-bag-line"
                                    ></i>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="elementor-element elementor-element-8e88dd0 jkit-equal-height-disable elementor-widget elementor-widget-jkit_icon_box"
                              data-id="8e88dd0"
                              data-element_type="widget"
                              data-widget_type="jkit_icon_box.default"
                            >
                              <div class="elementor-widget-container">
                                <div class="jeg-elementor-kit jkit-icon-box icon-position- elementor-animation- jeg_module_837_8_66a66a16d522c">
                                  <div class="jkit-icon-box-wrapper hover-from-left">
                                    <div class="icon-box icon-box-body">
                                      <h3 class="title">
                                        Cash exchange and delivery
                                      </h3>
                                      <p class="icon-box-description">
                                        Let's help you get quick cash and
                                        exhange that foreign currency, lets make
                                        your transactions seamless
                                      </p>
                                    </div>
                                  </div>
                                </div>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </body>
    </>
  );
};

export default Services;
